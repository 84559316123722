import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Link } from 'gatsby';

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404: Nepostojeća stranica" />
    <section className="container my-10 text-center text-xl">
      <h2 className="text-2xl font-semibold">404: Nepostojeća stranica</h2>
      <p className="mt-8">Pristupili ste adresi za koju ne postoji stranica.</p>
      <p className="mt-2">
        Vratite se na{' '}
        <Link className="font-medium underline" to="/">
          početnu stranicu
        </Link>
        .
      </p>
    </section>
  </Layout>
);

export default NotFoundPage;
